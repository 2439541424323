<template>
  <div>
    <loading v-if="isBusy" :is-busy="isBusy"></loading>
    <iframe
      v-else
      :src="mapUrl"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      class="vh-100 vw-100"
    ></iframe>
  </div>
</template>

<script>
import { Geolocation } from '@capacitor/geolocation'
import Loading from '../../components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
      lat: null,
      lang: null,
      mapUrl: '',
      isBusy: false,
    }
  },
  mounted() {
    this.getCurrentLoaction()
    // // this.watchPosition();
    // this.checkPermission();
  },
  methods: {
    async getCurrentLoaction() {
      this.isBusy = true
      try {
        const coordinates = await Geolocation.getCurrentPosition()
        this.isBusy = false
        if (coordinates) {
          this.lat = coordinates.coords.latitude
          this.lang = coordinates.coords.longitude
          this.mapUrl = `https://maps.google.com/maps?q=atm&origin=${this.lat},${this.lang}&center=${this.lat},${this.lang}&z=10&ie=UTF8&iwloc=&output=embed`
        }
      } catch (err) {
        this.isBusy = false
        this.mapUrl = `https://maps.google.com/maps?q=atm&z=10&ie=UTF8&iwloc=&output=embed`
        this.showAlert(err.message)
      }
    },
    watchPosition() {
      const wait = Geolocation.watchPosition({}, (position, err) => {
        console.log(err)
        this.lat = position.coords.latitude
        this.lang = position.coords.longitude
        this.mapUrl = `https://maps.google.com/maps?q=atm&origin=${this.lat},${this.lang}&center=${this.lat},${this.lang}&z=10&ie=UTF8&iwloc=&output=embed`
      })

      console.log(wait)
    },
  },
}
</script>

<style></style>
